<template>
  <div>
    <div class="header">
      <div class="container-fluid">
        <div class="wrapper">
          <div class="row">
            <div
              class="
                col-sm-4
                p-1
                d-flex
                justify-content-center
                align-items-center
                my-1
              "
            >
              <router-link :to="{ name: 'home' }" class="logo">
                <img alt="logo" src="/assets/images/logo.png" />
              </router-link>
            </div>
            <div
              class="
                col-sm-4
                offset-sm-4
                p-1
                d-flex
                justify-content-center
                align-items-center
                my-1
              "
            >
              <div class="registration dropdown">
                <a
                  id="cabinetDropdown"
                  aria-expanded="false"
                  aria-haspopup="true"
                  class="dropdown-toggle"
                  data-toggle="dropdown"
                  href="#"
                  type="button"
                >
                  {{ user.username }}
                </a>
                <div aria-labelledby="cabinetDropdown" class="dropdown-menu">
                  <router-link
                    :to="{ name: 'user_account' }"
                    class="dropdown-item"
                  >
                    Profil
                  </router-link>
                  <a class="dropdown-item" @click.prevent="logout"> logout </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "DisplayLayout.vue",
  computed: {
    loggedIn: function () {
      return this.$store.getters["user/loggedIn"];
    },
    user: function () {
      return this.$store.getters["user/getUser"];
    },
  },
  methods: {
    logout: function () {
      if (confirm("Tizmidan chiqmoqchimis?")) {
        this.$store.dispatch("user/logout");
      }
    },
  },
};
</script>
